
import React from "react";
import{Redirect} from "react-router-dom";
import "./ContactUs.scss";
import Page from "app/utils/Page/Page";
import Header from "app/components/molecules/Header/Header";
import ContactForm from "../ContactForm/ContactForm";
import { useSiteData } from "app/dataContext";
import { root } from "app/utils/helper";


const Reply = () => {

  const data = useSiteData();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const expTime = urlParams.get("exp");
  const toEmail = urlParams.get("to");
  const toName = urlParams.get("name");
  const subject = urlParams.get("subject");

  const currentTimeInMilliseconds = Date.now();
  const oneMinuteInMilliseconds = 60000; // expires in 60 seconds 

  if(currentTimeInMilliseconds - expTime > oneMinuteInMilliseconds){
    return (
      <Redirect to={`${root}`} />
    )
  }


  const contactData = data.contact.filter(item => item.phone !== 'header')[0];

  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="SR CaringCare Support"
      description="SR CaringCare Support" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="contact" />

        <section className="sub-section contact-us">

          <div className="left reply">
              <ContactForm  type="reply"  reciever={{toEmail, toName, subject}} />
          </div>

        </section>

      </div>

    </Page>

  )};

  export default Reply;

 