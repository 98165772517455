import React from "react";
import { useSiteData } from "app/dataContext";
import "./Quotes.scss";




const Quotes = (props) => {

  const {page} = props;

  let quotes = undefined;// {text:  "We rise by lifting others", person:"Robert Ingersoll", page: page}

  const data = useSiteData();



  if(data.quotes.length === 0){
    return null;
  }

  if(data.quotes !== undefined && data.quotes.length > 0){

    quotes = data.quotes.filter(d => d.page === page)[0]

  }

  if(!quotes) return null;

  return (
    <section>
      <div className="quote-section">
        <p>
          “{quotes.text}”
        </p>
        {quotes.person &&
        <p>-{quotes.person}</p>
        }
      </div>
    </section>

  )
}

export default Quotes;
