
import React, { useEffect, useState } from "react";
import Page from "app/utils/Page/Page";
import { NavLink } from "react-router-dom";
import "./Testimonials.scss";
import Header from "app/components/molecules/Header/Header";
import Quotes from "app/components/atoms/Quotes/Quotes";
import RenderHTML from "app/components/atoms/RenderHtml";
import { useSiteData } from "app/dataContext";
import Paragraph from "app/components/molecules/Paragraph/Paragraph";

const Testimonials = (props) => {
  const data = useSiteData();
  
  const homeData = data.home.filter((d) => d.type === null || d.type === 'H1' || d.type === 'H2' || d.type === 'H3')
  const tabItems = data.home.filter(item => item.type === 'tab');


  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="SR CaringCare Support"
      description="SR CaringCare Support" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">


        <Header page="services" />



        {/* {data.screenWidth > 1100 && */}
        <section className="sub-section">


          <div className="home-detail">
            <Paragraph data={[...homeData]}/>
          </div>



        </section>





        <Quotes page="home" />




        {/* <section className="projects-section" >

          {data.home &&
            data.home.map((item, i) => {

              const blockClass = i % 2 ? 'project-block' : 'project-block pb-reversed';
              const descClass = i % 2 ? "desc" : "desc desc-rev";
              const imgClass = i % 2 ? "image gs_reveal gs_reveal_fromLeft" : "image gs_reveal gs_reveal_fromRight";
              const h1Class = i % 2 ? "gs_reveal gs_reveal_fromLeft" : "gs_reveal gs_reveal_fromRight";
              //const pClass = "pd-text";
              const pClass = i % 2 ? "pd-text gs_reveal gs_reveal_fromRight" : "pd-text gs_reveal gs_reveal_fromLeft";


              let img = "./images/health/" + item.img;

              return (
                <div className='block' key={i} >

                  <div className={blockClass}>

                    <img className={imgClass} src={img} />

                    <div className={descClass}>
                      <h2 className={h1Class}>{item.title}</h2>

                      {item.detail &&
                        <p >
                          <RenderHTML HTML={item.detail} />
                        </p>
                      }
                      {item.link && (item.title === 'Who we are') &&
                        <div className="btn-more-cont">
                          <NavLink to={item.link} className="btn-readmore" >{item.linkLabel}</NavLink>
                        </div>
                      }

                    </div>

                  </div>
                </div>
              )
            })
          }
        </section> */}


      </div>

    </Page>
  )
}

export default Testimonials;
