
import React, { Component } from 'react';
import { APP_API, CONTACT_FORM_ID } from "../../../../config";
//import debounce from "just-debounce-it";
import { validateEmail, validateMessage } from "../../../utils/helper";
// import ToxicityDetector from './ToxicityDetector';
// import SpamDetector from 'app/utils/SpamDetector/SpamDetector';
import axios from "axios";
import "./ContactForm.scss";
//import { data } from '@tensorflow/tfjs';

const errorMsg = ' All fields are required.';
const defaultError = `${errorMsg}`;
let errors = '';


const GOONDROOK_API_END_POINT = "https://api.goondrook.com";  /// using the goondrook api to lambda function

//
// Component
// -----------------------------------------------------------

class ContactForm extends Component {

  constructor() {
    super();
    this.state = {
      formStatus: 'submit',
      formValid: false,
      data: { email: '', name: "", subject: '', message: '' }
    }
  }

  componentDidMount = () => {

    const data = {
      email: this.props.reciever ? this.props.reciever.toEmail : "",
      name: this.props.type === "reply" ? "SR CaringCare Team" : "",
      subject: this.props.reciever ? this.props.reciever.subject : "",
      message: this.props.reciever ? "Hi " + this.props.reciever.toName : ""

    }
    this.setState({ data })
  }



  onSpamOrToxicDetection = (item) => {
    const errorDiv = document.getElementById("errorDiv")
    const isToxicMsg = "Invalid Message. Inappropriate words or sentences detected in the message.";
    const isSpamMsg = "Invalid Message. Spams detected in the message.";

    const inValidMsgError = item === 'spam' ? isSpamMsg : isToxicMsg;
    errorDiv.innerHTML = inValidMsgError;
    errorDiv.classList.add('blink_me');
    this.setState({ formValid: false, formStatus: 'submit' });
  }

  /**  Usigin OpenAi to check spam or toxicity  */
  // isMesageSpamOrToxic = async (subject, message) => {

  //   if (subject === "" && message === "") {
  //     console.log("subject or message required")
  //     return;
  //   }

  //   let data = {
  //     subject: subject ? subject : "",
  //     message: message ? message : ""
  //   }

  //   const response = await fetch(server + "/spamdetection", {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  //   });

  //   if (!response.ok) {
  //     const errorMessage = await response.text();
  //     console.log(errorMessage)
  //   }
  //   const res = await response.json();

  //   return res.data.isspam === "True" ? "spam" : res.data.istoxic === "True" ? "toxic" : ""
  // }



  sendForm = async () => {
    const { data, formValid } = this.state;

    if (errors) {
      const errorDiv = document.getElementById("errorDiv")
      errorDiv.innerHTML = errors;
      errorDiv.classList.add('blink_me');
      this.setState({ formValid: false })
    }


    if (!errors) {

      this.setState({ formStatus: 'submitting' });
      const errorDiv = document.getElementById("errorDiv")

      try {
        // const isSpamToxic = await this.isMesageSpamOrToxic("", data.message);
        // if (isSpamToxic) {
        //   this.onSpamOrToxicDetection(isSpamToxic);
        //   return;
        // }

        const payload = {
          type: this.props.type === "reply" ? "srcaringcare_reply" : "srcaringcare_contact",
          name: data.name,
          message: data.message,
          subject: data.subject,
          to: [data.email]
        };

        try {
          const response = await fetch(GOONDROOK_API_END_POINT + "/dispatch_alerts", {
            method: 'post',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            errorDiv.innerHTML = "There was an error sending your message. Please try again later.";
            throw new Error(message);
          }
          const res = await response.json();

          this.setState({ formStatus: 'submitted', formValid: false, data: { email: '', name: '', subject: '', message: '' } });

          return res;

        } catch (err) {

          throw new Error('Failed to fetch name. ' + err.message);


          this.setState({ formStatus: 'submitted', formValid: false, data: { email: '', name: '', subject: '', message: '' } });

        }

      } catch (error) {
        console.log(`Error:`, error);

        errorDiv.innerHTML = "There was an error sending your message. Please try again later.";
        this.setState({ formValid: false, formStatus: 'submit' });
      }

    }

  }




  onInputChange = (e) => {

    const { data } = this.state;


    const errorDiv = document.getElementById("errorDiv");
    errorDiv.innerHTML = "";


    this.setState({ data: { ...data, [e.target.id]: e.target.value }, formStatus: 'submit' }, () => {

      const newData = this.state.data;

      for (const [key, value] of Object.entries(newData)) {

        if (value === '') {
          errors = `Fields cannot be empty. ${errorMsg}`;
          return;
        }

        if (key === 'email' && !validateEmail(value)) {
          errors = `Invalid email. Check your email.`;
          return;
        }

        if (key === 'message' && !validateMessage(value)) {
          errors = `Invalid or incomplete message.`;
          return;
        }

      }

      errors = ''

    })

  }




  render() {
    const { formStatus, formValid, data } = this.state;
    const { type } = this.props;
    const customClass = type === "reply" ? 'contact-form reply-form' : 'contact-form';
    const heading = type === "reply" ? 'Reply to an email' : 'Fill up the form';
    const emailLabel = type === "reply" ? "To email *" : "Your email *";
    const buttonLabel = type === "reply" ? "Submit" : "Get in touch";


    return (
      <div className={customClass}  >

        <h3>{heading}</h3>
        {type !== "reply" &&
          <p className="msg">Your email address will not be published. Required fields are marked *</p>
        }
        <div id="errorDiv"> </div>
        <input type="text" id="email" className="newsletter-input email" placeholder={emailLabel} onChange={this.onInputChange} value={data.email} />

        {type !== "reply" &&
          <input type="text" id="name" className="newsletter-input name" placeholder="Your name *" onChange={this.onInputChange} value={data.name} />
        }

        <input type="text" id="subject" className="newsletter-input subject" placeholder="Subject *" onChange={this.onInputChange} value={data.subject} />
        <textarea id="message" className="newsletter-input message" placeholder="Your Message *" rows="4" cols="50" onChange={this.onInputChange} value={data.message} />


        <button className={formValid && (formStatus === 'submit' || formStatus === 'submitted') ? 'btn-active' : ''} onClick={this.sendForm}>

          {formStatus === 'submitting' &&
            <>
              <span>Processing ...</span>
              <div id="spinner" className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </>
          }

          {(formStatus === 'submit' || formStatus === 'submitted') && <span>{buttonLabel}</span>}

        </button>

        {formStatus === 'submitted' && <span className="sent">Message sent</span>}



      </div>

    )
  }
}

export default ContactForm;





