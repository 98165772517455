import React from "react";
import { Route, Switch, Redirect, BrowserRouter as Router, ScrollRestoration } from "react-router-dom";
//import AuthenticatedRoute from "../utils/AuthenticatedRoute/AuthenticatedRoute";
import Loadable from "react-loadable";
// import DynamicImportLoader from "../components/atoms/DynamicImportLoader/DynamicImportLoader";
import NotFoundPage from "../components/organisms/NotFoundPage/NotFoundPage";
import ComingSoon from "../components/atoms/ComingSoon/ComingSoon";
import Home from "app/components/organisms/Home/Home";
import AboutUs from "app/components/organisms/AboutUs/AboutUs";
import Disclaimers from "app/components/organisms/Disclaimers/Disclaimers";
import Services from "app/components/organisms/Services/Services";
import Testimonials from "app/components/organisms/Testimonials/Testimonials"
import Partnerships from "app/components/organisms/Partnerships/Partnerships";
import Careers from "app/components/organisms/Careers/Careers";
import ContactUs from "app/components/organisms/ContactUs/ContactUs";
import Reply from "app/components/organisms/ContactUs/Reply";
import { root } from "app/utils/helper";


//
// Routes 
// ------------------------------------------------------------------

const Routes = (props) => (

    <Switch>

      <Route path={`${root}404`} component={NotFoundPage} />
      <Route exact path={`${root}comingsoon`} component={ComingSoon} />

      {/* Redirecting to richard lama portfolio for now */}
      <Route exact path={`${root}`} component={Home} />
      <Route exact path={`${root}aboutus`} component={AboutUs} />

      <Route exact path={`${root}testimonials`} component={Testimonials} />
      <Route exact path={`${root}services`} component={Services} />

      <Route exact path={`${root}partnerships`}  component={Partnerships} />
      <Route exact path={`${root}careers`} component={Careers} />

      <Route exact path={`${root}contactus`} component={ContactUs} />
      <Route exact path={`${root}reply`} component={Reply} />
      {/*<Route exact path={`${root}disclaimers`}  component={Disclaimers} /> */}

      <Redirect to={`${root}404`} />


    </Switch>

);

export default Routes;
