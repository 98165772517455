import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { root } from "app/utils/helper";
import { useSiteData } from "app/dataContext";
import './Footer.scss';



const Footer = (props) => {

  const sdata = useSiteData();
  const data = sdata.contact[0];

  if (!data) return null;

  return (
    <div className="footer-container">
      <div className="footer">

        <div className="footer__header">

          <div className="left-s">
            <a href={`https://www.srcaringcaresupport.com.au`}>
              <img src="images/srcare/footer-logo.jpg" />
            </a>
          </div>

          <div className="right-s">
            <div className="acknowledgement-box">
              <h3>Acknowledgement of Country</h3>
              <p>We acknowledge the traditional owners and custodians of country throughout Australia and acknowledges their continuing connection to land, water, and community. We pay our respects to the people, the cultures, and the elders, past, present, and emerging.</p>
            </div>

            <hr />




            <div className="box-b">
              <div className="copyright">
                <p className="copyright__text">© {new Date().getFullYear()} SR CaringCare Support.
                  <span style={{ margin: '0 10px' }}>ABN: 36255119066</span>
                  All Rights Reserved |
                  {/* <Link to={`${root}contactus`} lt="privacy policy"  >Privacy Policy</Link> | 
              <Link to={`${root}disclaimers`} lt="Disclaimers"  >Disclaimers</Link> |  */}
                  <Link to={`${root}contactus`} lt="contact us"  > Contact us</Link>
                </p>
              </div>


              <div className="social">
                <a href={data['facebook link']} target="_blank"><img className="cf-social" src="images/icons/Icon-facebook.svg" />  </a>
                <a href={data['linkedin link']} target="_blank"><img className="cf-social" src="images/icons/Icon-linkedin.svg" />  </a>
                {data['instagram link'] &&
                  <a href={data['instagram link']} target="_blank"><img className="cf-social" src="images/icons/Icon-instagram.svg" />  </a>
                }
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  )

}

export default Footer;
