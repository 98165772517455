
import React from "react";
import "./Services.scss";
import Page from "app/utils/Page/Page";
import Header from "app/components/molecules/Header/Header";
import Quotes from "app/components/atoms/Quotes/Quotes";
import RenderHTML from "app/components/atoms/RenderHtml";
import { useSiteData } from "app/dataContext";
import Paragraph from "app/components/molecules/Paragraph/Paragraph";


const SkillList = (props) => {
  const { data, img, title } = props;
  const list =
    data.map((item, i) => {
      if (item) {
        return (
          <li key={i}>{item}</li>
        )
      }
    })
  return (
    <div className="skill-list">
      <ul>
        {list}
      </ul>
    </div>

  )
}



const Services = () => {

  const data = useSiteData();


  const allList = useSiteData().lists;
  let skillsList = [];

  allList.forEach(element => {
    skillsList.push(element['High Intensity Care'])
  });

  //,'srcaringcare@gmail.com'
  const boxItems = data.services.filter(item => item.type === 'box');
  const serviesPars = data.services.filter(item => item.type === null || item.type === 'H1' || item.type === 'H2' || item.type === 'H3');

  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="SR CaringCare Support"
      description="SR CaringCare Support" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="services" />

        <section className="sub-section services">

          <Paragraph data={[...serviesPars]} />

          <div className="box-items">
            {
              boxItems.map((item, i) => {

                const boxImg = "./images/srcare/" + item.image;
                const boxIcon = "./images/icons/" + item.icon;

                return (
                  <div className="box" key={i}>
                    <div className="content"  >
                      <div className="img-cont">
                        <img className="img" src={boxImg} />
                        <img className="icon" src={boxIcon} />
                      </div>
                      <h3>{item.value}</h3>
                      <p><RenderHTML HTML={item.detail} /></p>


                      {item.value === 'High Intensity Care' &&
                        <>
                          <p style={{ margin: '25px 0 10px', fontWeight: '700' }}>Skills covered in our training may include:</p>
                          <div style={{ padding: '0 20px 20px' }}>
                            <div className="skill-list-box">
                              <SkillList data={skillsList} />
                            </div>
                          </div>
                        </>
                      }

                    </div>
                  </div>

                )

              })
            }
          </div>


        </section>

      </div>


      <div className="sub-section testimonials-cont">
        <h3>Testimonials</h3>
        <div className="testimonials">
          {
            data.testimonials.map((item, i) => {

              if (item.detail) {
                return (
                  <div key={i} className="box" >

                    <p className="details">{item.detail}</p>
                    <p className="name">{item.name}</p>
                    <p className="role">{item.role}</p>

                  </div>
                )
              }
            })
          }

        </div>
      </div>


      <Quotes page="services" />



    </Page>
  )
}

export default Services;
