
import React, { Component, useState, useEffect } from "react";
import SvgIcons from "./utils/SvgIcons/SvgIcons";
import NavBar from "./components/organisms/NavBar/NavBar";
import Routes from "./routes/routes";
import "./App.scss";
import MakeAppointment from "./components/atoms/MakeAppointment/MakeAppointment";
import fallBackData from "./fallback-data.json";
import { useSiteData } from "./dataContext";


const isDevelopement = process.env.NODE_ENV === 'development';


const App = (props) => {
  const [showFooter, setShowFooter] = useState(false);
  const {screenWidth} = useSiteData();

  return (
    <div id="app">
      <SvgIcons carat cross social doublearrow loading sideArrow />
      <NavBar
        isBeta={true}
      />
      {/* {screenWidth < 768 &&
        <div className="mobile-make-appt">
          <MakeAppointment />
        </div>
      } */}
      <Routes/>

    </div>
  )

}

export default App;

