
import React from "react";
import "./AboutUs.scss";
import Page from "app/utils/Page/Page";
import { capitalizeFirstLetter } from "app/utils/helper";
import Header from "app/components/molecules/Header/Header";
import RenderHTML from "app/components/atoms/RenderHtml";
import { useSiteData } from "app/dataContext";
import Paragraph from "app/components/molecules/Paragraph/Paragraph";
import Quotes from "app/components/atoms/Quotes/Quotes";

const Pinklist = (props) => {
  const { data, img, title } = props;
  const list =
    data.map((item, i) => {
      if (item) {
        return (
          <li key={i}>{item}</li>
        )
      }
    })
  return (
    <div className="pink-list">
      <div><span><img src={img} /></span>{title}</div>
      <ul>
        {list}
      </ul>
    </div>

  )
}


const AboutUs = () => {
  const data = useSiteData();
  const aboutValues = useSiteData().lists;

  let aboutUsValuesData = { commitment: [], courage: [], respect: [] };

  // find the order of  commitment, courage, respect
  let listOrder = [], index = 0;
  for (const key in aboutValues[0]) {
    if (index <= 2) {
      listOrder.push(key.toLowerCase())
    }
    index++;
  }

  aboutValues.forEach(element => {
    aboutUsValuesData.commitment.push(element.Commitment)
    aboutUsValuesData.courage.push(element.Courage)
    aboutUsValuesData.respect.push(element.Respect)
  });

  const aboutData = data.aboutUs.filter(item => item.type === null || item.type === 'H1' || item.type === 'H2' || item.type === 'H3');



  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="SR CaringCare Support"
      description="SR CaringCare Support" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="aboutUs" />

        <div className="sub-section aboutus">

          <Paragraph data={[...aboutData]} />


          {/* <div className="pink-list-box">
            {
              listOrder.map((item, i) => {

                return (
                  <Pinklist key={`er-${i}`} data={aboutUsValuesData[item]} img={`./images/icons/icon-${item}-about-us.svg`} title={capitalizeFirstLetter(item)} />
                )
              })

            }
          </div> */}


        </div>

      </div>

      <Quotes page="aboutus" />

    </Page>
  )
}

export default AboutUs;
