
import React from "react";
import "./Partnerships.scss";
import Page from "app/utils/Page/Page";
import Header from "app/components/molecules/Header/Header";
import RenderHTML from "app/components/atoms/RenderHtml";
import { useSiteData } from "app/dataContext";
import Paragraph from "app/components/molecules/Paragraph/Paragraph";
import Quotes from "app/components/atoms/Quotes/Quotes";


const NetworkList = (props) => {
  const { data, img, title } = props;
  const list =
    data.map((item, i) => {
      if (item) {
        return (
          <li key={i}>{item}</li>
        )
      }
    })
  return (
    <div className="skill-list">
      <ul>
        {list}
      </ul>
    </div>

  )
}


const Partnerships = () => {

  const data = useSiteData().partnerships;
  const partnersData = data.filter(item => item.type === null || item.type === 'H1' || item.type === 'H2' || item.type === 'H3');


  const aboutValues = useSiteData().lists;
  let networkList = [];
  let partnerLogos = [];
  let partnerLogosLinks = [];

  aboutValues.forEach(element => {
    networkList.push(element['Network list'])
    partnerLogos.push(element['Partner logos'])
    partnerLogosLinks.push(element['Partner logos link'])
  });

  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="SR CaringCare Support"
      description="SR CaringCare Support" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="partnerships" />

        <section className="sub-section partnerships">

        <Paragraph data={[...partnersData]}/>

          {/* <div className="skill-list-box">
            <NetworkList data={networkList} />
          </div> */}

            <h3>Our Network</h3>
          <div className="logos">
            {
              partnerLogos.map((item, i) => {

                if (item) {
                  return (
                    <a key={i} className="logos-div" href={partnerLogosLinks[i]} target="_blank">
                      {/* <a href={partnerLogosLinks[i]} target="_blank"> */}
                        <img src={`images/${item}`} />
                      {/* </a> */}
                    </a>
                  )
                }
              })
            }

          </div>

        </section>

      </div>

      <Quotes page="partnerships" />

    </Page>
  )
}

export default Partnerships;
