
import React from "react";
import RenderHTML from "app/components/atoms/RenderHtml";
import "./Paragraph.scss"

const Paragraph = (props) => {

    return (
        <span className="paragraph">
            {
                props.data.map((item, i) => {

                    if (item.type === 'H1') {
                        return (
                            <h1 key={i} >{item.value}</h1>
                        )
                    } else
                        if (item.type === 'H2') {
                            return (
                                <h2 key={i} >{item.value}</h2>
                            )
                        } else
                            if (item.type === 'H3') {
                                return (
                                    <h3 key={i} >{item.value}</h3>
                                )
                            } else {
                                return (
                                    <p key={i} ><RenderHTML HTML={item.value} /></p>

                                )
                            }
                })
            }
        </span>
    )
}

export default Paragraph;
