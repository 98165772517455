import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from "./app/App";
import { SiteDataProvider } from 'app/dataContext';
import "./index.scss";


ReactDOM.render(
   <SiteDataProvider>
      <BrowserRouter>
         <App />
      </BrowserRouter>
   </SiteDataProvider>
   , document.querySelector('#root'));

