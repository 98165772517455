import React from "react";
import { Link } from "react-router-dom";
import { useSiteData } from "app/dataContext";
import RenderHTML from "app/components/atoms/RenderHtml";
import "./Header.scss";


function getScreenOrientation() {
  if (window.innerWidth > window.innerHeight) {
    return 'landscape';
  } else {
    return 'portrait';
  }
}

const Header = (props) => {

  const { page } = props;
  const data = useSiteData();

  let header;
  if (page === 'contact') {

    header = data[page].filter(item => item.phone === 'header')[0];  /// note `phone` object
  } else {
    header = data[page].filter(item => item.type === 'header')[0];

  }

  let headerImg = header.image;// data.headerImg[page].img;
  const orientation = getScreenOrientation();
  headerImg = orientation === 'portrait' ? "./images/srcare/" + headerImg.split(".")[0] + "_mobile." + headerImg.split(".")[1] : "./images/srcare/" + headerImg;

  const headerTxt = page === 'contact' ? header.email : header.value;

  const siteMap = page === 'aboutus' ? 'Home / About Us' :
    page === 'services' ? 'Home / Services' :
      page === 'partnerships' ? 'Home / Partnerships' :
        page === 'contactus' ? 'Home / Contact Us' : '';


  return (
    <>
      {page !== 'home' &&
        <section className="header-solid"  >
          <div className="content" style={{ backgroundColor: 'yellow', backgroundImage: `url('${headerImg}')` }} >

            <div className="holder"  style={{ backgroundColor: '#0330dfcc' }} >
              <div className="hero-content">

                <div className="left-s"  >

                  {/* <div className="left-s-A"></div> */}

                  <div className="details">

                    {/* <p className="site-map">{siteMap}</p> */}

                    <div className="sub-details">
                      <div className="cont">
                        {/* <img src="images/icons/white-heart-icon.svg" /> */}
                        <p className="header-tip-teal">{page}</p>
                        <h2 className="header-h2">{headerTxt}</h2>
                      </div>

                    </div>

                    <div></div>

                  </div>
                </div>

                <div className="right-s" ></div>

              </div>
            </div>
          </div>
        </section>
      }


      {
        page === 'home' &&
        <div className="header">
          <section className="header-gradient"  >


            <div className="content" style={{ backgroundImage: `url('${headerImg}')` }}>
              {/* <div className="pink-gradient"></div> */}
              <div className="hero-content" >

                <div className="left-s" >

                  <div className="left-s-A"></div>

                  <div className="details">

                    <div className="sub-details">

                      {/* <p className="welcome">{headerTxt}</p> */}
                      <h2><RenderHTML HTML={headerTxt} /> </h2>

                      <Link className="btn-more" to="/aboutus">More about us</Link>

                    </div>

                    <div></div>

                  </div>

                </div>

                <div className="right-s"></div>

              </div>
            </div>



          </section>
        </div>
      }
    </>

  )
}

export default Header;



