import React, { createContext, useContext, useEffect, useState } from 'react';

export const SiteDataContext = createContext();


export const SiteDataProvider = ({ children }) => {
  
  const windowdata = { ...window.sitedata };
  const [data, setSiteData] = useState(windowdata);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth)
      }
      window.addEventListener("resize", handleResize);

  }, []);


  return (
    <SiteDataContext.Provider value={{ ...data, screenWidth: screenWidth}}>
      {children}
    </SiteDataContext.Provider>
  );
}


export const useSiteData = () => {
  return useContext(SiteDataContext);
};

